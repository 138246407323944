<template>
    <span :class="{'green--text': isGoUp, 'red--text': !isGoUp }">{{ currentPrice | NumFormat }}</span>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import config from '@/config.js';
    import { mapGetters } from "vuex";
    import pako from 'pako';
    export default {
        data(){
            return{
                // WebSocket对象
                websocket: null,
                // 当前价格
                currentPrice: '--',
                // 收益损失率
                percentageChange: 0,
                // 是上涨趋势吗
                isGoUp: true,
                // 收益损失率的颜色
                percentageChangeColor: 'green--text',
                // 是否触发
                triggered: true
            }
        },
        props: {
            // 交易所代码
            exchangeCode: { type: String },
            // 交易类型
            tradingCategory: { type: String },
            // 交易对
            symbol: { type: String },
            // 类型：fill(成交)/bid/ask
            type: { type: String },
            // 进入价
            entryPrice: { type: Number, default: 0 },
            // 交易方向
            side: {type: String, default: 'long' },
            // 周期
            timeInterval: {type: String, default: null },
            // 小数位数
            decimalNumber : { type: Number, default: 5 }
        },
        computed: {
            ...mapGetters(['countryCode'])
        },
        created(){
            // 数字格式化
            Vue.filter('NumFormat', function(value) {
                if (value == '--') return '--';
                if(!value) return '0.00';
                var intPart = Number(value).toFixed(0); //获取整数部分
                intPart = intPart > Number(value) ? intPart - 1 : intPart; // 若四舍五入进了1则减去1
                var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
                var floatPart = ".00"; //预定义小数部分
                var value2Array = value.split(".");
                //=2表示数据有小数位
                if(value2Array.length == 2) {
                    floatPart = value2Array[1].toString(); //拿到小数部分
                    if(floatPart.length == 1) { //补0,实际上用不着
                        return intPartFormat + "." + floatPart + '0';
                    } else {
                        return intPartFormat + "." + floatPart;
                    }
                } else {
                    return intPartFormat + floatPart;
                }
            })
            this.initWebSocket()
        },
        mounted(){

        },
        watch:{
            exchangeCode(newVal, oldVal){
                if (newVal != oldVal) {
                    this.currentPrice = '--';
                    if (this.websocket) {
                        this.websocket.close();
                        this.websocket = null;
                    }
                    this.initWebSocket();
                }
            },
            tradingCategory(newVal, oldVal){
                if (newVal != oldVal) {
                    this.currentPrice = '--';
                    if (this.websocket) {
                        this.websocket.close();
                        this.websocket = null;
                    }
                    this.initWebSocket();
                }
            },
            symbol(newVal, oldVal){
                if (newVal != oldVal) {
                    this.currentPrice = '--';
                    if (this.websocket) {
                        this.websocket.close();
                        this.websocket = null;
                    }
                    this.initWebSocket();
                }
            },
            type(newVal, oldVal){
                if (newVal != oldVal) {
                    this.currentPrice = '--';
                    if (this.websocket) {
                        this.websocket.close();
                        this.websocket = null;
                    }
                    this.initWebSocket();
                }
            },
            currentPrice(newVal, oldVal){
                if (newVal != '--' && oldVal != '--') {
                    let newPrice = Number(newVal);
                    let oldPrice = Number(oldVal);
                    if (newPrice > oldPrice) {
                        this.isGoUp = true;
                    } else if (newPrice < oldPrice) {
                        this.isGoUp = false;
                    }
                }
                if (this.currentPrice != '--' && this.entryPrice != 0) {
                    let profit = 0;
                    if ('long' == this.side) {
                        if (this.currentPrice >= this.entryPrice) {
                            this.triggered = true;
                        } else {
                            this.triggered = false;
                        }
                        profit = (this.currentPrice - this.entryPrice) / this.entryPrice;
                    } else if ('short' == this.side) {
                        if (this.currentPrice <= this.entryPrice) {
                            this.triggered = true;
                        } else {
                            this.triggered = false;
                        }
                        profit = (this.entryPrice - this.currentPrice) / this.entryPrice;
                    }
                    if (profit < 0) {
                        this.percentageChangeColor = 'red--text';
                    }
                    let percentage = Number(profit * 100).toFixed(2);
                    this.percentageChange = (percentage > 0 ? '+' : '') + percentage + '%';
                } else {
                    this.percentageChange = '--';
                }
            },
            // 监听PL百分比的改变
            percentageChange(newVal, oldVal){
                if (newVal != oldVal) {
                    // let num =  Number(newVal.replace('+', '').replace('-', '').replace('%', ''));
                    // let color = 'green--text';
                    // if (num < 0) {
                    //     color = 'red--text';
                    // }
                    let data = {
                        symbol: this.symbol,
                        timeInterval: this.timeInterval,
                        data: newVal,
                        color: this.percentageChangeColor,
                        triggered: this.triggered
                    };
                    this.$emit('percentageChange', this.symbol, this.timeInterval, data);
                }
            }
        },
        methods: {
            // 初始化websocket
            initWebSocket(){
                let url = this.getWebSocketUrl(this.exchangeCode, this.tradingCategory);
                if (url == null || url == '') {
                    return;
                }
                this.websocket = new WebSocket(url);
                this.websocket.onmessage = this.websocketOnMessage;
                this.websocket.onerror = this.websocketOnError;
                this.websocket.onopen = this.websocketOnOpen;
                this.websocket.onclose = this.websocketOnClose;
            },
            // 连接建立之后执行send方法发送数据
            websocketOnOpen(){
                let data = '';
                let symbolPair = this.getSymbol(this.exchangeCode, this.tradingCategory, this.symbol);
                if (symbolPair == null || symbolPair == '') {
                    if (this.websocket) {
                        this.websocket.close();
                        this.websocket = null;
                    }
                    return;
                }
                switch (this.exchangeCode) {
                    case 'kraken':
                        switch (this.tradingCategory) {
                            case 'spot':
                                let subscriptionName = "ticker";
                                if ('fill' == this.type) {
                                    subscriptionName = "ticker";
                                } else {
                                    subscriptionName = "spread";
                                }
                                data = {
                                    event: "subscribe",
                                    pair: [
                                        symbolPair
                                    ],
                                    subscription: {
                                        name: subscriptionName
                                    }
                                };
                                break;
                            case 'futures':
                                data = {
                                    event: "subscribe",
                                    feed: "ticker",
                                    product_ids: [symbolPair]
                                };
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'okex':
                        switch (this.tradingCategory) {
                            case 'spot':
                                data = {
                                    op: "subscribe",
                                    args: [
                                        symbolPair
                                    ]
                                };
                                break;
                            case 'futures':
                                data = {
                                    op: "subscribe",
                                    args: [
                                        symbolPair
                                    ]
                                };
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                // let msg = '{"event":"subscribe", "feed":"ticker", "product_ids":["PI_XBTUSD"]}';
                this.websocketSend(JSON.stringify(data));
            },
            websocketOnError(){
                // 连接失败，进行重连
                this.initWebSocket();
            },
            // 收到消息，解析数据
            websocketOnMessage (e) {
                let message = e.data;
                switch (this.exchangeCode) {
                    case 'kraken':
                        switch (this.tradingCategory) {
                            case 'spot':
                                switch (this.type) {
                                    case 'fill':
                                        if (message.indexOf("ticker") != -1 && message.indexOf("event") == -1) {
                                            let arr = JSON.parse(message);
                                            for (let i = 0; i < arr.length; i++) {
                                                if ('object' == typeof(arr[i])) {
                                                    let price = arr[i].c[0];
                                                    this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                                    // 匹配到数据跳出循环
                                                    break;
                                                }
                                            }
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    case 'bid':
                                        if (message.indexOf("spread") != -1 && message.indexOf("event") == -1) {
                                            let arr = JSON.parse(message);
                                            for (let i = 0; i < arr.length; i++) {
                                                if ('object' == typeof(arr[i])) {
                                                    let price = arr[i][0];
                                                    this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                                    // 匹配到数据跳出循环
                                                    break;
                                                }
                                            }
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    case 'ask':
                                        if (message.indexOf("spread") != -1 && message.indexOf("event") == -1) {
                                            let arr = JSON.parse(message);
                                            for (let i = 0; i < arr.length; i++) {
                                                if ('object' == typeof(arr[i])) {
                                                    let price = arr[i][1];
                                                    this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                                    // 匹配到数据跳出循环
                                                    break;
                                                }
                                            }
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    default:
                                        this.currentPrice = '--';
                                        break;
                                }
                                break;
                            case 'futures':
                                switch (this.type) {
                                    case 'fill':
                                        if (message.indexOf("ticker") != -1 && message.indexOf("event") == -1) {
                                            let ticker = JSON.parse(message);
                                            let price = ticker.last;
                                            this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    case 'bid':
                                        if (message.indexOf("ticker") != -1 && message.indexOf("event") == -1) {
                                            let ticker = JSON.parse(message);
                                            let price = ticker.bid;
                                            this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    case 'ask':
                                        if (message.indexOf("ticker") != -1 && message.indexOf("event") == -1) {
                                            let ticker = JSON.parse(message);
                                            let price = ticker.ask;
                                            this.currentPrice = Number(price).toFixed(this.decimalNumber);
                                        } else {
                                            // 订阅成功或心跳包数据不用处理
                                        }
                                        break;
                                    default:
                                        this.currentPrice = '--';
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'okex':
                        switch (this.tradingCategory) {
                            case 'spot':
                                switch (this.type) {
                                    case 'fill':
                                        let _this = this;
                                        var reader = new FileReader();
                                        reader.readAsBinaryString(message);
                                        reader.onload = function (evt) {
                                          var data = pako.inflateRaw(evt.target.result, { to: 'string' })
                                          // 转换后
                                          let result = JSON.parse(data);
                                          if(result.table == 'spot/ticker'){
                                            let ticker = result.data[0];
                                            _this.currentPrice = Number(ticker.last).toFixed(_this.decimalNumber);
                                          }
                                        }
                                        break;
                                    case 'bid':
                                        break;
                                    case 'ask':
                                        break;
                                    default:
                                        this.currentPrice = '--';
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        this.currentPrice = '--';
                        break;
                }
            },
            // 数据发送
            websocketSend (data) {
                if (this.websocket) {
                    this.websocket.send(data)
                }
            },
            // 关闭连接
            websocketOnClose (e) {
                // 关闭连接
            },
            // 获得交易所订阅WebSocket的url
            getWebSocketUrl(exchangeCode, tradingCategory){
                let url = '';
                switch (exchangeCode) {
                    case 'kraken':
                        switch (tradingCategory) {
                            case 'spot':
                                url = 'wss://ws.kraken.com';
                                break;
                            case 'futures':
                                if ('' == config.environment) {
                                    url = 'wss://futures.kraken.com/ws/v1';
                                } else {
                                    url = 'wss://demo-futures.kraken.com/ws/v1';
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    case 'okex':
                        switch (tradingCategory) {
                            case 'spot':
                                if(this.countryCode == 'CN'){
                                  url = 'wss://okexcomreal.bafang.com:8443/ws/v3';
                                } else {
                                  url = 'wss://real.okex.com:8443/ws/v3';
                                }
                                break;
                            case 'futures':
                                if(this.countryCode == 'CN'){
                                  url = 'wss://okexcomreal.bafang.com:8443/ws/v3';
                                } else {
                                  url = 'wss://real.okex.com:8443/ws/v3';
                                }
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                return url;
            },
            // 根据交易所code和和交易类型和符号更换交易所的订阅符号
            getSymbol(exchangeCode, tradingCategory, symbol){
                return getWebSocketSymbol(exchangeCode, tradingCategory, symbol);
            }
        },
        // 销毁事件
        destroyed(){
            // 离开路由之后断开websocket连接
            if (this.websocket) {
                this.websocket.close();
                this.websocket = null;
            }
        }
    }
</script>
